import React, { useState, useEffect } from "react";
import NavBar from "../Components/navbar";
import DailyChores from "../Components/DailyChores";
import DishChores from "../Components/DishChores";

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const API_BASE_URL = "http://chores-backend-production-b7e2.up.railway.app";

const Home = () => {

const [shiftedKids, setShiftedKids] = useState([]);
const [shiftedFamily, setShiftedFamily] = useState([]);
const [showDailyChores, setShowDailyChores] = useState(true);
const [currentKidsErr, setCurrentKidsErr] = useState("");
const [currentFamilyErr, setCurrentFamilyErr] = useState("");


const fetchData = async (url, setState, setError) => {
  try {
    const response = await fetch(url, {method:"GET", credentials:'include'});
    if (!response.ok) {
      throw new Error("Could not fetch the data for that resource");
    }
    const data = await response.json();
    setState(data.member);
  } catch (err) {
    console.log(err);
    setError(err.message);
  }
};

 
    useEffect(() => {
      setTimeout(() => {
        fetchData(`${API_BASE_URL}/members/kids`, setShiftedKids, setCurrentKidsErr);
        fetchData(`${API_BASE_URL}/members/family`, setShiftedFamily, setCurrentFamilyErr);
      }, 1000);
    }, []);

  

  
  
  const kidsNameList = shiftedKids.slice(0, 5).map(kid => (
    <li key={kid.id}>{kid.name}</li>
  ));
  
  const dateTime = new Date();
  const weekday = dateTime.getDay();
  const dayNum = dateTime.getDate();

  return (
    <div>
      <NavBar />
      <div className='main'>
        <h2>Welcome to the chore site</h2>
        <p>{daysOfWeek[weekday]} the {dayNum}</p>
        <div className="premise">
          <p id="top">I made this because people kept moving people in the wrong places. This will move chores automatically so that it stays in order.</p>
          <p id='bottom'>Remember to do your chores even if you leave for the day 😁</p>
          
        </div>
      </div>

      <div className="current-kids-div">
        <div className="currentKids">
          <h2>Current Kids:</h2>
          <p>{currentKidsErr !== "" && currentKidsErr}</p>
          <ul>{kidsNameList}</ul>
        </div>
        
        <div className="buttons-div">
        <button onClick={() => setShowDailyChores(prev => !prev)} className="button">
              Dish/Daily Chores
        </button>
        </div>
      </div>
      
     {showDailyChores ? <DailyChores kids={shiftedKids} /> : <DishChores family={shiftedFamily} />}
    
    </div>
  );
};

export default Home;
